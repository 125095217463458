import React, { Component } from 'react'
import Pagination from '../round/Pagination'
import './news.css'
import { get } from '../../ajax/tools'

class Notice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      NoticeListArr: [],
      NoticeListArrLength: 0,
      NoticeListBrr: [],
      PageSize: '',
      CurrentPage: 1,
    }
  }
  componentDidMount = function () {
    let H = window.innerHeight - 340 > 360 ? window.innerHeight - 340 : 360
    let PageSize = Math.ceil(H / 52)
    get({ url: 'index/news_show' }).then((res) => {
      if (res.errno === '200') {
        let HomeNewBrr = []
        for (let i = 0; i < res.data.length; i++) {
          let NewType = res.data[i].category_id
          let sb = res.data[i].status
          if (NewType === 2 && sb === 1) {
            let HomeNewArrList = res.data[i]
            HomeNewBrr.push(HomeNewArrList)
          }
        }
        const KeyStart = (this.state.CurrentPage - 1) * PageSize
        const KeyEnd = this.state.CurrentPage * PageSize
        const NewsCenterList = []
        if (KeyEnd <= HomeNewBrr.length) {
          for (let i = KeyStart; i < KeyEnd; i++) {
            const NewsCenterListBrr = HomeNewBrr[i]
            NewsCenterList.push(NewsCenterListBrr)
          }
        } else {
          for (let i = KeyStart; i < HomeNewBrr.length; i++) {
            const NewsCenterListBrr = HomeNewBrr[i]
            NewsCenterList.push(NewsCenterListBrr)
          }
        }

        this.setState({
          NoticeListArr: HomeNewBrr,
          NoticeListBrr: NewsCenterList,
          NoticeListArrLength: HomeNewBrr.length,
          PageSize: PageSize,
        })
      } else {
        this.setState({
          NoticeListArr: [],
          NoticeListBrr: [],
        })
      }
    })
  }

  NoticeCurrentPage(CurrentPage) {
    const KeyStart = (CurrentPage - 1) * this.state.PageSize
    const KeyEnd = CurrentPage * this.state.PageSize
    const NoticeList = []
    if (KeyEnd <= this.state.NoticeListArr.length) {
      for (let i = KeyStart; i < KeyEnd; i++) {
        const NoticeBList = this.state.NoticeListArr[i]
        NoticeList.push(NoticeBList)
      }
    } else {
      for (let i = KeyStart; i < this.state.NoticeListArr.length; i++) {
        const NoticeBList = this.state.NoticeListArr[i]
        NoticeList.push(NoticeBList)
      }
    }
    this.setState({
      NoticeListBrr: NoticeList,
    })
  }

  NewsInfoList = (item, index) => {
    if (item.is_pdf && item.is_pdf === 1) {
      window.open(item.content)
    } else {
      window.open('/newsInfo?id=' + item.id)
    }
  }

  render() {
    return (
      <div>
        <div
          className={'InfoListBox'}
          style={{
            height:
              window.innerHeight - 340 > 360 ? window.innerHeight - 340 : 360,
          }}
        >
          {this.state.NoticeListBrr.map((item, index) => (
            <div
              className={'InfoList'}
              key={index}
              onClick={() => this.NewsInfoList(item, index)}
            >
              <div className={'InfoListKey'}>
                [<span style={{ color: '#ff0000' }}>公告</span>]
              </div>
              <div className={'InfoListTitle'} style={{ width: 500 }}>
                {item.title}
              </div>
              <div className={'InfoListCenter'} style={{ width: 160 }}>
                ·········
              </div>
              <div className={'InfoListTime'}>{item.sort_time}</div>
            </div>
          ))}
        </div>
        <div className={'InfoListBorder'} style={{ marginBottom: 10 }}>
          {' '}
        </div>
        <div style={{ marginBottom: 20 }}>
          {this.state.NoticeListArrLength !== 0 && (
            <Pagination
              TotalSize={this.state.NoticeListArrLength}
              PageSize={this.state.PageSize}
              pageCallbackFn={this.NoticeCurrentPage.bind(this)}
            />
          )}
        </div>
      </div>
    )
  }
}

export default Notice
