import React, { Component } from 'react'
import Header from '../handf/Header'
import Footer from '../handf/Footer'
import PopUp from '../round/PopUp'
import NewsCenter from './NewsCenter'
import Notice from './Notice'
import NewsBulletin from './NewsBulletin'

import './news.css'

//import {post,} from "../../ajax/tools";

class New extends Component {
  constructor(props) {
    super(props)
    this.state = {
      winHeight: '',
      NowNavListZhName: '通知公告',
      NowNavListEnName: 'notice',
      NewNavArr: [
        {
          ZhName: '通知公告',
          EnName: 'notice',
        },
        {
          ZhName: '新闻中心',
          EnName: 'News Center',
        },
        {
          ZhName: '相关政策',
          EnName: 'related policy',
        },
      ],
    }
  }
  componentDidMount = function () {
    const contentHeight = document.body.scrollHeight //网页正文全文高度
    const winHeight = window.innerHeight //可视窗口高度，不包括浏览器顶部工具栏
    const ua = navigator.userAgent.toLowerCase() //媒体查询当前设备
    const windows = ua.indexOf('windows')
    const macintosh = ua.indexOf('macintosh')
    const linux = ua.indexOf('linux')
    if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
      this.setState({
        winHeight: winHeight,
      })
    } else if (windows === -1 && macintosh === -1 && linux === -1) {
      this.setState({
        winHeight: '',
      })
    } else {
      this.setState({
        winHeight: '',
      })
    }
  }

  //点击左侧菜单
  AccountListClick = (item, index) => {
    this.setState({
      NowNavListZhName: item.ZhName,
      NowNavListEnName: item.EnName,
    })
  }

  //提示弹窗
  ChildBackTip = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    }
  }

  render() {
    return (
      <div className={'MainBox'} style={{ height: this.state.winHeight }}>
        <Header />
        <div className={'MainContent'}>
          <div className={'MainTopBox clearfix'}>
            <div className={'MainConTitle'}>
              <div className={'MainConTitZH'}>
                {this.state.NowNavListZhName}
              </div>
              <div className={'MainConTitEN'}>News&nbsp;&nbsp;bulletin</div>
            </div>
            <div className={'MainConTitBorder'}> </div>
            <div className={'MainActiveListTitle'}>
              {this.state.NowNavListEnName}
            </div>
          </div>
          <div
            className={'ContentMainBox clearfix'}
            style={{ height: window.innerHeight - 191 }}
          >
            <div
              className={'MainLeftNavBox scrollBar'}
              style={{
                height:
                  window.innerHeight - 191 > 460
                    ? window.innerHeight - 191
                    : 460,
              }}
            >
              <ul className={'MainLeftNavListBox'}>
                {this.state.NewNavArr.map((item, index) => (
                  <li
                    className={
                      item.ZhName === this.state.NowNavListZhName
                        ? 'MainLeftNavActiveList'
                        : 'MainLeftNavList'
                    }
                    key={index}
                    onClick={() => this.AccountListClick(item, index)}
                  >
                    {item.ZhName}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={'MainThisNavBox scrollBar'}
              style={{ height: window.innerHeight - 191 }}
            >
              <div className={'InfoListPositionTitBox'}>
                <div className={'InfoListPositionTit'}>
                  <div className={'InfoListPosition'}>
                    新闻公告&gt;&gt;{this.state.NowNavListZhName}
                  </div>
                </div>
              </div>
              <div className={'InfoListBorder'}> </div>
              {this.state.NowNavListZhName === '新闻中心' && <NewsCenter />}

              {this.state.NowNavListZhName === '通知公告' && <Notice />}
              {this.state.NowNavListZhName === '相关政策' && <NewsBulletin />}
            </div>
          </div>
          {/*<div className={'PositionBottom'}> </div>*/}
        </div>
        <Footer />
        {this.state.PopUp === '提示弹窗' && (
          <PopUp
            ChildBackClick={this.ChildBackTip.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
      </div>
    )
  }
}

export default New
