import React, { Component } from 'react'
import Pagination from '../round/Pagination'
import './news.css'
import { get } from '../../ajax/tools'
//import {post,} from "../../ajax/tools";

class NewsCenter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      NewsCenterListArr: [],
      NewsCenterListArrLength: 0,
      NewsCenterListBrr: [],
      PageSize: '',
      CurrentPage: 1,
    }
  }
  componentDidMount = function () {
    let H = window.innerHeight - 340 > 360 ? window.innerHeight - 340 : 360
    let PageSize = Math.ceil(H / 52)
    get({ url: 'index/news_show' }).then((res) => {
      if (res.errno === '200') {
        let HomeNewBrr = []
        for (let i = 0; i < res.data.length; i++) {
          let NewType = res.data[i].category_id
          let sb = res.data[i].status
          if (NewType === 1 && sb === 1) {
            let HomeNewArrList = res.data[i]
            HomeNewBrr.push(HomeNewArrList)
          }
        }
        const KeyStart = (this.state.CurrentPage - 1) * PageSize
        const KeyEnd = this.state.CurrentPage * PageSize
        const NewsCenterList = []
        if (KeyEnd <= HomeNewBrr.length) {
          for (let i = KeyStart; i < KeyEnd; i++) {
            const NewsCenterListBrr = HomeNewBrr[i]
            NewsCenterList.push(NewsCenterListBrr)
          }
        } else {
          for (let i = KeyStart; i < HomeNewBrr.length; i++) {
            const NewsCenterListBrr = HomeNewBrr[i]
            NewsCenterList.push(NewsCenterListBrr)
          }
        }

        this.setState({
          NewsCenterListArr: HomeNewBrr,
          NewsCenterListBrr: NewsCenterList,
          NewsCenterListArrLength: HomeNewBrr.length,
          PageSize: PageSize,
        })
      } else {
        this.setState({
          NewsCenterListArr: [],
          NewsCenterListBrr: [],
        })
      }
    })
  }

  getCurrentPage(CurrentPage) {
    const KeyStart = (CurrentPage - 1) * this.state.PageSize
    const KeyEnd = CurrentPage * this.state.PageSize
    const NewsCenterList = []
    if (KeyEnd <= this.state.NewsCenterListArr.length) {
      for (let i = KeyStart; i < KeyEnd; i++) {
        const NewsCenterBList = this.state.NewsCenterListArr[i]
        NewsCenterList.push(NewsCenterBList)
      }
    } else {
      for (let i = KeyStart; i < this.state.NewsCenterListArr.length; i++) {
        const NewsCenterBList = this.state.NewsCenterListArr[i]
        NewsCenterList.push(NewsCenterBList)
      }
    }
    this.setState({
      NewsCenterListBrr: NewsCenterList,
    })
  }

  NewsInfoList = (item, index) => {
    if (item.is_pdf && item.is_pdf === 1) {
      window.open(item.content)
    } else {
      window.open('/newsInfo?id=' + item.id)
    }
  }

  render() {
    return (
      <div>
        <div
          className={'InfoListBox'}
          style={{
            height:
              window.innerHeight - 340 > 360 ? window.innerHeight - 340 : 360,
          }}
        >
          {this.state.NewsCenterListBrr.map((item, index) => (
            <div
              className={'InfoList'}
              key={index}
              onClick={() => this.NewsInfoList(item, index)}
            >
              <div className={'InfoListKey'}>
                [<span style={{ color: '#ff0000' }}>新闻</span>]
              </div>
              <div className={'InfoListTitle'}>{item.title}</div>
              <div className={'InfoListCenter'}>············</div>
              <div className={'InfoListTime'}>{item.sort_time}</div>
            </div>
          ))}
        </div>
        <div className={'InfoListBorder'} style={{ marginBottom: 10 }}>
          {' '}
        </div>
        <div style={{ marginBottom: 20 }}>
          {this.state.NewsCenterListArrLength !== 0 && (
            <Pagination
              TotalSize={this.state.NewsCenterListArrLength}
              PageSize={this.state.PageSize}
              pageCallbackFn={this.getCurrentPage.bind(this)}
            />
          )}
        </div>
      </div>
    )
  }
}

export default NewsCenter
