import React, { Component } from 'react'
import './member.css'
import '../round/round.css'
import PopUp from '../round/PopUp'
import DatePicker from '../round/DatePicker'
import ECharts from './ECharts'
import AnAlyECharts from './AnAlyECharts'
import { get, post } from '../../ajax/tools'
import Triangle from '../round/Triangle'
import ExportJsonExcel from 'js-export-excel'
import CheckboxIcoHave from './memberimage/CheckboxIcoHave.png'
import CheckboxIcoNot from './memberimage/CheckboxIcoNot.png'
import Ranking from './Ranking'

class GrYunCheng extends Component {
  constructor(props) {
    super(props)
    this.state = {
      YunChengState: '',

      YCShowArr: [], //拥有云程的本机构下属训练或航校
      YCSchoolId: '',
      YCSchoolType: '',

      YCStuShowArr: [], //拥有云程的机构学员列表

      StartTime: '', //开始时间
      EndTime: '', //结束时间
      YCItemName: '', //科目名称
      YCItemId: '', //科目名称
      YCItemArr: [], //科目列表
      YCItemToggleOn: true,
      YCItemDisplay: 'none',

      SelectInfo: {},
      YCEChartsState: '',

      TheStuId: '',

      StuNumIdCard: '',
      StuNumIdCardAllBtn: 'no',

      AddStuNumIdCard: '',
      AddStuNumIdCardInfo: 'no',
      AddPeopleInfo: {},

      AddListArr: [],

      StuOfSchoolArr: [],
      StuOfSchoolId: '',
      StuOfSchoolToggleOn: true,
      StuOfSchoolDisplay: 'none',

      AlreadyNumArr: [],
      AlreadyNum: 0,

      IsAnAly: false, //是否是竞技类项目
      IsAnAlyArr: [],
      AnAlyState: 'no',
      AnAlyErrMsg: '',

      YcCodeInput: '',
      YcCodeArr: [],
      CodeOrgId: '',

      YCScoreType: '',
      YCScoreArr: [],

      AddClassName: '',
      AddClassArr: [],
      AddClassArrTip: '',
      StuOfClassId: '',
      StuOfClassToggleOn: true,
      StuOfClassDisplay: 'none',
      YunChengPopUp: '',
      TheStuYCId: '',

      CheckboxToggleOn: 'false',
    }
  }
  componentDidMount = function () {
    get({ url: 'cloudprogram/show_train' }).then((res) => {
      if (res.errno === '200') {
        if (res.data.length !== 0) {
          this.setState({
            YCShowArr: res.data,
            YunChengState: 'KeyList',
          })
        } else {
          this.setState({
            YCShowArr: res.data,
            YunChengState: 'NotKey',
          })
        }
      } else {
        this.setState({
          YunChengState: 'NotKey',
        })
      }
    })
    document.onclick = this.AllClick.bind(this) //下拉菜单监听隐藏
  }

  AllClick = () => {
    this.setState({
      YCItemToggleOn: true,
      YCItemDisplay: 'none',
      StuOfSchoolToggleOn: true,
      StuOfSchoolDisplay: 'none',
      StuOfClassToggleOn: true,
      StuOfClassDisplay: 'none',
    })
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  YCShowClick = (item, index) => {
    if (item.isanalysis === 1) {
      get({ url: 'cloudprogram/show_cloudpro_stu/' + item.id }).then((res) => {
        if (res.errno === '200') {
          let YCStuShowBrr = []
          for (let i = 0; i < res.data.length; i++) {
            let TeamListStatus = res.data[i].status
            if (TeamListStatus === 1) {
              YCStuShowBrr.push(res.data[i])
            }
          }
          this.setState({
            YCStuShowArr: YCStuShowBrr,
            YCSchoolId: item.id,
            YCSchoolType: item.type,
            YunChengState: 'StuList',
          })
        } else if (res.errno === '4002') {
          this.setState({
            YCStuShowArr: [],
            YCSchoolId: item.id,
            YCSchoolType: item.type,
            YunChengState: 'StuList',
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })

      get({ url: 'cloudprogram/train_stu_school/' + item.id }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            StuOfSchoolArr: res.data,
          })
        } else {
          this.setState({
            StuOfSchoolArr: [],
          })
        }
      })
      post({
        url: 'cloudprogram/show_class_cloud',
        data: {
          ti_id: item.id,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            AddClassArr: res.data,
          })
        } else {
          this.setState({
            AddClassArr: [],
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '当前账户暂不支持数据分析！',
      })
    }
  }

  //选择此机构下的所有学员的学校列表点开
  StuOfSchoolDown(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      StuOfClassToggleOn: true,
      StuOfClassDisplay: 'none',
    })
    let StuOfSchoolArr = this.state.StuOfSchoolArr
    if (StuOfSchoolArr.length !== 0) {
      this.setState((prevState) => ({
        StuOfSchoolToggleOn: !prevState.StuOfSchoolToggleOn,
        StuOfSchoolDisplay: prevState.StuOfSchoolToggleOn ? 'block' : 'none',
      }))
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '暂无数据！',
      })
    }
  }
  //选择此机构下的所有学员的学校列表点击选择学校以筛选
  StuOfSchoolList = (item, index) => {
    let YCSchoolId = this.state.YCSchoolId
    post({
      url: 'cloudprogram/get_train_school_stu',
      data: {
        ti_id: YCSchoolId,
        school_id: item.user_id,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          YCStuShowArr: res.data,
          StuOfSchoolId: item.user_id,
          StuNumIdCardAllBtn: 'yes',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  //班级功能
  //班级功能
  //班级功能
  //班级功能
  //班级功能
  //班级功能
  //选择此机构下的所有学员的学校列表点开
  StuOfClassDown(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      StuOfSchoolToggleOn: true,
      StuOfSchoolDisplay: 'none',
    })
    let AddClassArr = this.state.AddClassArr
    if (AddClassArr.length !== 0) {
      this.setState((prevState) => ({
        StuOfClassToggleOn: !prevState.StuOfClassToggleOn,
        StuOfClassDisplay: prevState.StuOfClassToggleOn ? 'block' : 'none',
      }))
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '暂未区分班级！',
      })
    }
  }
  //选择此机构下的所有学员的学校列表点击选择学校以筛选
  StuOfClassList = (item, index) => {
    let YCSchoolId = this.state.YCSchoolId
    post({
      url: 'cloudprogram/show_class_cloud_stu',
      data: {
        ti_id: YCSchoolId,
        class_id: item.id,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          YCStuShowArr: res.data,
          StuOfClassId: item.id,
          StuNumIdCardAllBtn: 'yes',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }
  YCStuClassClick = (item, index) => {
    let YCSchoolId = this.state.YCSchoolId
    post({
      url: 'cloudprogram/show_class_cloud',
      data: {
        ti_id: YCSchoolId,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          AddClassArr: res.data,
          TheStuId: item.user_id,
          TheStuYCId: item.id,
          YunChengPopUp: 'YCMoveClass',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  ClassConfirm = (item, index) => {
    let YCSchoolId = this.state.YCSchoolId
    let TheStuYCId = this.state.TheStuYCId
    post({
      url: 'cloudprogram/add_class_stu',
      data: {
        cloud_stu_id: TheStuYCId,
        class_id: item.id,
      },
    }).then((res) => {
      if (res.errno === '200') {
        get({ url: 'cloudprogram/show_cloudpro_stu/' + YCSchoolId }).then(
          (res) => {
            if (res.errno === '200') {
              let YCStuShowBrr = []
              for (let i = 0; i < res.data.length; i++) {
                let TeamListStatus = res.data[i].status
                if (TeamListStatus === 1) {
                  YCStuShowBrr.push(res.data[i])
                }
              }
              this.setState({
                YCStuShowArr: YCStuShowBrr,
                StuNumIdCardAllBtn: 'no',
                StuOfSchoolId: '',
                StuOfClassId: '',
                StuNumIdCard: '',
                YunChengPopUp: '',
                PopUp: '提示弹窗',
                MessageTip: '操作班级成功！',
              })
            }
          }
        )
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  ClassCrossOut() {
    this.setState({
      YunChengPopUp: '',
    })
  }

  //机构搜索学员
  StuNumIdCard(event) {
    //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
    this.setState({
      StuNumIdCard: event.target.value,
    })
  }
  //点击搜索
  StuNumIdCardSearchBtn() {
    let YCSchoolId = this.state.YCSchoolId
    let StuNumIdCard = this.state.StuNumIdCard
    post({
      url: 'cloudprogram/query_show_cloudpro_stu',
      data: {
        ti_id: YCSchoolId,
        real_name: StuNumIdCard,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          YCStuShowArr: res.data,
          StuNumIdCardAllBtn: 'yes',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }
  //全部数据
  StuNumIdCardSearchAllBtn() {
    let YCSchoolId = this.state.YCSchoolId
    get({ url: 'cloudprogram/show_cloudpro_stu/' + YCSchoolId }).then((res) => {
      if (res.errno === '200') {
        let YCStuShowBrr = []
        for (let i = 0; i < res.data.length; i++) {
          let TeamListStatus = res.data[i].status
          if (TeamListStatus === 1) {
            YCStuShowBrr.push(res.data[i])
          }
        }
        this.setState({
          YCStuShowArr: YCStuShowBrr,
          StuNumIdCardAllBtn: 'no',
          StuOfSchoolId: '',
          StuOfClassId: '',
          StuNumIdCard: '',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  //添加班级按钮
  AddClassBtn() {
    this.setState({
      YunChengState: 'AddClassBtn',
    })
    let YCSchoolId = this.state.YCSchoolId
    post({
      url: 'cloudprogram/show_class_cloud',
      data: {
        ti_id: YCSchoolId,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          AddClassArr: res.data,
          AddClassArrTip: 'yes',
        })
      } else {
        this.setState({
          AddClassArr: [],
          AddClassArrTip: 'no',
        })
      }
    })
  }
  AddClassName(event) {
    //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
    this.setState({
      AddClassName: event.target.value,
    })
  }
  //点击新建
  AddClassOkBtn() {
    let AddClassName = this.state.AddClassName
    let YCSchoolId = this.state.YCSchoolId
    if (AddClassName !== '') {
      post({
        url: 'cloudprogram/create_class',
        data: {
          ti_id: YCSchoolId,
          class_name: AddClassName,
        },
      }).then((res) => {
        if (res.errno === '200') {
          post({
            url: 'cloudprogram/show_class_cloud',
            data: {
              ti_id: YCSchoolId,
            },
          }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                AddClassArr: res.data,
                AddClassArrTip: 'yes',
                PopUp: '提示弹窗',
                MessageTip: '新建成功！',
                AddClassName: '',
              })
            } else {
              this.setState({
                AddClassArr: [],
                AddClassArrTip: 'no',
              })
            }
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请输入新建的班级名称！',
      })
    }
  }
  ClassRemove = (item, index) => {
    let YCSchoolId = this.state.YCSchoolId
    post({
      url: 'cloudprogram/del_class_cloud ',
      data: {
        class_id: item.id,
      },
    }).then((res) => {
      if (res.errno === '200') {
        post({
          url: 'cloudprogram/show_class_cloud',
          data: {
            ti_id: YCSchoolId,
          },
        }).then((res) => {
          if (res.errno === '200') {
            this.setState({
              AddClassArr: res.data,
              PopUp: '提示弹窗',
              MessageTip: '删除成功！',
              AddClassName: '',
            })
          }
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  //添加学员按钮
  AddStuBtn() {
    this.setState({
      YunChengState: 'Add',
      AddStuNumIdCard: '',
      AddStuNumIdCardInfo: 'no',
      AddPeopleInfo: {},
    })
  }
  AddStuNumIdCard(event) {
    //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
    this.setState({
      AddStuNumIdCard: event.target.value,
    })
  }
  //点击搜索
  AddStuNumIdCardSearchBtn() {
    let AddStuNumIdCard = this.state.AddStuNumIdCard
    if (AddStuNumIdCard !== '') {
      get({ url: 'reuse/get_personal_info/' + AddStuNumIdCard }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            AddPeopleInfo: res.data[0],
            AddStuNumIdCardInfo: 'yes',
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请输入人员ID或身份证号！',
      })
    }
  }
  //添加此学员
  AddStuBtnOk() {
    let AddPeopleInfo = this.state.AddPeopleInfo
    let YCSchoolId = this.state.YCSchoolId
    if (AddPeopleInfo.id) {
      post({
        url: 'cloudprogram/add_cloudpro_stu',
        data: {
          ti_id: YCSchoolId,
          stu_id: AddPeopleInfo.id,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '未知错误，请联系我们！',
      })
    }
  }

  //添加纪录按钮
  AddListBtn() {
    let YCSchoolId = this.state.YCSchoolId
    get({ url: 'cloudprogram/show_cloudpro_stu/' + YCSchoolId }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          AddListArr: res.data,
          YunChengState: 'AddList',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  //返回的按钮
  StuListBack() {
    this.setState({
      YunChengState: 'KeyList',
    })
  }
  AddBack() {
    this.setState({
      YunChengState: 'StuList',
    })
    let YCSchoolId = this.state.YCSchoolId
    get({ url: 'cloudprogram/show_cloudpro_stu/' + YCSchoolId }).then((res) => {
      if (res.errno === '200') {
        let YCStuShowBrr = []
        for (let i = 0; i < res.data.length; i++) {
          let TeamListStatus = res.data[i].status
          if (TeamListStatus === 1) {
            YCStuShowBrr.push(res.data[i])
          }
        }
        this.setState({
          YCStuShowArr: YCStuShowBrr,
        })
      }
    })
  }
  AddListBack() {
    this.setState({
      YunChengState: 'Add',
    })
  }
  CodeBack() {
    this.setState({
      YunChengState: 'KeyList',
      YcCodeInput: '',
      YcCodeArr: [],
      CodeOrgId: '',
    })
  }

  YCStuShowClick = (item, index) => {
    get({ url: 'user/get_all_routine_item' }).then((res) => {
      if (res.errno === '200') {
        function sortId(a, b) {
          return a.item_level - b.item_level
        }
        let sb = res.data.sort(sortId)
        this.setState({
          YCItemArr: sb,
        })
      } else {
        this.setState({
          YCItemArr: [],
        })
      }
    })
    this.setState({
      TheStuId: item.user_id,
      YunChengState: 'ECharts',
      YCEChartsState: 'Logo',
    })
  }

  EChartsBack() {
    this.setState({
      YunChengState: 'StuList',
      YCItemName: '',
    })
  }

  //删除学员
  YCStuRemoveClick = (item, index) => {
    let YCSchoolId = this.state.YCSchoolId
    get({ url: 'cloudprogram/del_cloudpro_stu/' + item.id }).then((res) => {
      if (res.errno === '200') {
        get({ url: 'cloudprogram/show_cloudpro_stu/' + YCSchoolId }).then(
          (res) => {
            if (res.errno === '200') {
              let YCStuShowBrr = []
              for (let i = 0; i < res.data.length; i++) {
                let TeamListStatus = res.data[i].status
                if (TeamListStatus === 1) {
                  YCStuShowBrr.push(res.data[i])
                }
              }
              this.setState({
                YCStuShowArr: YCStuShowBrr,
              })
            }
          }
        )
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  StartTime = (event) => {
    this.setState({
      StartTime: event,
    })
  }
  EndTime = (event) => {
    this.setState({
      EndTime: event,
    })
  }

  YCItemDown(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      YCItemToggleOn: !prevState.YCItemToggleOn,
      YCItemDisplay: prevState.YCItemToggleOn ? 'block' : 'none',
    }))
  }

  YCItemClick = (item, index) => {
    this.setState({
      YCItemId: item.id,
      YCItemName: item.name,
      YCItemToggleOn: true,
      YCItemDisplay: 'none',
    })
  }

  YCButton() {
    let StartTime = this.state.StartTime
    let EndTime = this.state.EndTime
    let YCItemId = this.state.YCItemId
    let YCItemName = this.state.YCItemName
    let TheStuId = this.state.TheStuId
    let YCSchoolType = this.state.YCSchoolType
    let YCSchoolId = this.state.YCSchoolId
    if (StartTime !== '' && EndTime !== '' && YCItemName !== '') {
      let SelectInfo = {
        StartTime: StartTime,
        EndTime: EndTime,
        YCItemId: YCItemId,
        YCItemName: YCItemName,
        TheStuId: TheStuId,
        YCSchoolType: YCSchoolType,
        YCSchoolId: YCSchoolId,
      }
      post({
        url: 'agency/stu_score_analy_list',
        data: {
          item_id: YCItemId,
          start_time: StartTime,
          end_time: EndTime,
          stu_id: TheStuId,
          type: YCSchoolType,
          ti_id: YCSchoolId,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            YCScoreType: 'OK',
            YCScoreArr: res.data,
          })
        } else {
          this.setState({
            YCScoreType: 'Err',
            YCScoreArr: [],
          })
        }
      })
      post({
        url: 'cloudprogram/compete_speed',
        data: {
          item_id: YCItemId,
          start_time: StartTime,
          end_time: EndTime,
          stu_id: TheStuId,
          type: YCSchoolType,
          ti_id: YCSchoolId,
        },
      }).then((res) => {
        if (res.errno === '200') {
          if (res.is_analy === 1) {
            this.setState({
              IsAnAly: true,
              IsAnAlyArr: res.data,
              AnAlyState: 'no',
              AnAlyErrMsg: '',
              SelectInfo: SelectInfo,
              YCEChartsState: 'Analysis',
            })
          } else {
            this.setState({
              AnAlyErrMsg: '',
              IsAnAly: false,
              AnAlyState: 'no',
              SelectInfo: SelectInfo,
              YCEChartsState: 'Analysis',
            })
          }
        } else {
          this.setState({
            AnAlyErrMsg: res.errmsg,
            IsAnAly: false,
            AnAlyState: 'no',
            SelectInfo: SelectInfo,
            YCEChartsState: 'Analysis',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请将开始、结束时间、项目选择完整！',
      })
    }
  }

  OtherEChartsBtn() {
    let IsAnAlyArr = this.state.IsAnAlyArr
    let AnAlyErrMsg = this.state.AnAlyErrMsg
    if (AnAlyErrMsg === '') {
      if (IsAnAlyArr.length !== 0) {
        this.setState({
          AnAlyState: 'yes',
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: '暂无数据',
        })
      }
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: AnAlyErrMsg,
      })
    }
  }
  OutOtherEChartsBtn() {
    this.setState({
      AnAlyState: 'no',
    })
  }

  //全选多选框
  CheckboxIcoAll() {
    let YCStuShowArrList = this.state.YCStuShowArr
    //console.log(YCStuShowArrList)
    let CheckboxToggleOn = this.state.CheckboxToggleOn
    let YCStuShow = []
    if (CheckboxToggleOn === 'false') {
      for (let i = 0; i < YCStuShowArrList.length; i++) {
        let TeamList = YCStuShowArrList[i]
        TeamList['Checkbox'] = 'true'
        YCStuShow.push(TeamList)
      }
      this.setState({
        AlreadyNumArr: YCStuShowArrList,
        AlreadyNum: YCStuShowArrList.length,
        CheckboxToggleOn: 'true',
        YCStuShowArr: YCStuShow,
      })
    } else if (CheckboxToggleOn === 'true') {
      for (let i = 0; i < YCStuShowArrList.length; i++) {
        let TeamList = YCStuShowArrList[i]
        TeamList['Checkbox'] = 'false'
        YCStuShow.push(TeamList)
      }
      this.setState({
        AlreadyNumArr: [],
        AlreadyNum: 0,
        CheckboxToggleOn: 'false',
        YCStuShowArr: YCStuShow,
      })
    }
  }

  //多选框操作
  ClickOnCheckBoxIco = (item, index) => {
    let YCStuShowArrList = this.state.YCStuShowArr
    YCStuShowArrList[index]['Checkbox'] =
      item.Checkbox === 'false' ? 'true' : 'false'
    let AlreadyNumArr = [...this.state.AlreadyNumArr]
    if (item.Checkbox === 'true') {
      AlreadyNumArr.push(item)
      this.setState({
        AlreadyNumArr: AlreadyNumArr,
        AlreadyNum: AlreadyNumArr.length,
      })
      if (AlreadyNumArr.length === YCStuShowArrList.length) {
        this.setState({
          CheckboxToggleOn: 'true',
        })
      } else {
        this.setState({
          CheckboxToggleOn: 'false',
        })
      }
    } else if (item.Checkbox === 'false') {
      for (let i = 0; i < AlreadyNumArr.length; i++) {
        if (AlreadyNumArr[i].id === item.id) {
          AlreadyNumArr.splice(i, 1)
        }
      }
      this.setState({
        AlreadyNumArr: AlreadyNumArr,
        AlreadyNum: AlreadyNumArr.length,
      })
      if (AlreadyNumArr.length === YCStuShowArrList.length) {
        this.setState({
          CheckboxToggleOn: 'true',
        })
      } else {
        this.setState({
          CheckboxToggleOn: 'false',
        })
      }
    }
    this.setState({
      YCStuShowArr: YCStuShowArrList,
    })
  }

  //成绩排行
  RankingBtn() {
    //let AlreadyNumArr = this.state.AlreadyNumArr;
    let AlreadyNum = this.state.AlreadyNum
    if (AlreadyNum >= 1) {
      this.setState({
        YunChengState: 'Ranking',
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请至少选择1人！',
      })
    }
  }
  RankingBackChild = (event) => {
    if (event === 'back') {
      this.setState({
        YunChengState: 'StuList',
      })
    }
  }

  EChartsBackChild = (event) => {
    if (event === 'Err') {
      this.setState({
        YCEChartsState: 'LogoErr',
        AnAlyErrMsg: '暂无数据，请重新选择其他项目！',
      })
    } else if (event === 'Login') {
      const { history } = this.props
      history.push('/login')
    }
  }

  //激活码部分
  YCCodeClick = (item, index) => {
    post({
      url: 'a_agency/query_active_code',
      data: {
        ti_id: item.id,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          YcCodeArr: res.data,
          YunChengState: 'YcCode',
          CodeOrgId: item.id,
        })
      } else {
        this.setState({
          YunChengState: 'YcCode',
          CodeOrgId: item.id,
          YcCodeArr: [],
        })
      }
    })
  }
  YcCodeInput = (event) => {
    //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
    this.setState({
      YcCodeInput: event.target.value,
    })
  }
  YcCodeInputBtn() {
    let CodeOrgId = this.state.CodeOrgId
    let YcCodeInput = this.state.YcCodeInput
    post({
      url: 'agency/convert_active_code',
      data: {
        ti_id: CodeOrgId,
        blank_verificat: YcCodeInput,
      },
    }).then((res) => {
      if (res.errno === '200') {
        post({
          url: 'a_agency/query_active_code',
          data: {
            ti_id: CodeOrgId,
          },
        }).then((res) => {
          if (res.errno === '200') {
            this.setState({
              YcCodeArr: res.data,
            })
          }
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  DownData() {
    let JoinInfoArr = this.state.YCScoreArr
    let currentPro = []
    if (JoinInfoArr.length !== 0) {
      for (let i = 0; i < JoinInfoArr.length; i++) {
        const obList = JoinInfoArr[i]
        currentPro.push(obList)
      }
    }
    let option = {}
    let dataTable = []
    let sheetBasicTitle = ['日期', '成绩时间']
    if (currentPro) {
      for (let i = 0; i < currentPro.length; i++) {
        let obj = {}
        obj.日期 = currentPro[i].ctime
        obj.成绩时间 =
          currentPro[i].time_score === 0
            ? currentPro[i].total_time !== ''
              ? currentPro[i].total_time + '秒'
              : currentPro[i].iscrash === 1
              ? '坠机'
              : currentPro[i].iscrash === 2
              ? currentPro[i].remark
              : '异常'
            : currentPro[i].time_score === 1
            ? currentPro[i].total_score !== ''
              ? currentPro[i].total_score + '分'
              : currentPro[i].iscrash === 1
              ? '坠机'
              : currentPro[i].iscrash === 2
              ? currentPro[i].remark
              : '异常'
            : currentPro[i].time_score === 2
            ? currentPro[i].total_score !== ''
              ? currentPro[i].total_score + '分'
              : (currentPro[i].iscrash === 1
                  ? '坠机'
                  : currentPro[i].iscrash === 2
                  ? currentPro[i].remark
                  : '异常') +
                  '/' +
                  currentPro[i].total_time !==
                ''
              ? currentPro[i].total_time + '秒'
              : currentPro[i].iscrash === 1
              ? '坠机'
              : currentPro[i].iscrash === 2
              ? currentPro[i].remark
              : '异常'
            : ''
        dataTable.push(obj)
      }
    }
    option.fileName = '历史成绩与时间数据表'
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet1',
        sheetFilter: sheetBasicTitle,
        sheetHeader: sheetBasicTitle,
        columnWidths: [16, 16],
      },
    ]
    if (dataTable.length !== 0) {
      const toExcel = new ExportJsonExcel(option)
      toExcel.saveExcel()
    }
  }

  BackGrService() {
    this.props.ChildBackNavClick('')
  }

  //提示弹窗
  ChildBackTip = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    }
  }

  render() {
    return (
      <div
        className={'MemberPackageBox scrollBar clearfix'}
        style={{ height: window.innerHeight - 180 }}
      >
        {this.state.YunChengState === 'NotKey' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{ padding: '6px 0', height: 36 }}
            >
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.BackGrService.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
            </div>
            <div style={{ padding: '170px 0' }}>
              <div className={'TrainDataMain'}>
                <div className={'keyNumLogo'}> </div>
                {/*<div className={'keyNumTitle'}>贵单位缺少‘云程’服务，未加入统一电子化管理，请与服务平台联系</div>*/}
                <div className={'keyNumTitle'} style={{ height: 'auto' }}>
                  <span>使用云程过程中如有疑问请联系:</span>
                  {/*<span style={{color:'#60c7ef',}}>*/}
                  {/*    您第一次使用云程，请您*/}
                  {/*    <a*/}
                  {/*        style={{color:'#ff0000',cursor:'pointer',}}*/}
                  {/*        href={'http://oss.asfcyy.com/richTextImage/71c3b454a99811ec9dc700163e3521a2.docx'}*/}
                  {/*        download={'http://oss.asfcyy.com/richTextImage/71c3b454a99811ec9dc700163e3521a2.docx'}*/}
                  {/*    >下载文档</a>*/}
                  {/*    填写完整后发送至*/}
                  {/*</span>*/}
                  {/*yuncheng@asfcyy.com*/}
                  {/*<span style={{color:'#60c7ef',}}>邮箱，客服将在第一时间为您提供云程使用咨询，帮助您更好的安装使用云程1.0产品</span>*/}
                </div>
                <div className={'keyNumTitle'} style={{ height: 'auto' }}>
                  <span
                    style={{ color: '#000', fontSize: 22, fontWeight: 'bold' }}
                  >
                    米老师：13691581580
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.YunChengState === 'KeyList' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{
                borderBottom: '2px solid #DDDDDD',
                padding: '6px 0',
                height: 36,
              }}
            >
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.BackGrService.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
            </div>
            <div className={'GrGameHeaderBox'}>
              <div className={'GrGameHeaderInfo'} style={{ width: 300 }}>
                飞行数据电子化管理
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 400 }}>
                地址
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 200 }}>
                设备管理
              </div>
            </div>
            <div
              className={'GrGameContentBox scrollBar'}
              style={{ height: window.innerHeight - 290 }}
            >
              {this.state.YCShowArr.map((item, index) => (
                <div className={'GrGameContentList YcClickList'} key={index}>
                  <div className={'GrGameContentInfo'} style={{ width: 300 }}>
                    <span
                      style={{ color: '#6DC1FF', textDecoration: 'underline' }}
                      onClick={() => this.YCShowClick(item, index)}
                    >
                      {item.name}
                    </span>
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 400 }}>
                    <span>
                      {item.province === item.city
                        ? item.city + item.address
                        : item.province + item.city + item.address}
                    </span>
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 200 }}>
                    <span
                      style={{ color: '#6DC1FF', textDecoration: 'underline' }}
                      onClick={() => this.YCCodeClick(item, index)}
                    >
                      操作
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {this.state.YunChengState === 'StuList' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{
                borderBottom: '2px solid #DDDDDD',
                padding: '6px 0',
                height: 36,
              }}
            >
              <div className={'AppSearchBox clearfix'}>
                <div className={'AppSearchInputBox'}>
                  <input
                    className={'SearchInput'}
                    type={'text'}
                    name={'SearchInput'}
                    value={this.state.StuNumIdCard}
                    onChange={this.StuNumIdCard.bind(this)}
                    placeholder={'请输入学员姓名搜索'}
                    autoComplete={'off'}
                  />
                </div>
                <div
                  className={'AppSearchBtn'}
                  onClick={this.StuNumIdCardSearchBtn.bind(this)}
                >
                  搜索
                </div>
                {this.state.StuNumIdCardAllBtn === 'yes' && (
                  <div
                    className={'AppSearchBtn'}
                    style={{ float: 'left', marginLeft: 6 }}
                    onClick={this.StuNumIdCardSearchAllBtn.bind(this)}
                  >
                    全部数据
                  </div>
                )}
              </div>
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.StuListBack.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
              <div className={'AddStuBtn'} onClick={this.AddStuBtn.bind(this)}>
                添加
              </div>
              <div
                className={'AddStuBtn'}
                style={{
                  backgroundColor: '#FDAD4D',
                  width: 'auto',
                  padding: '0 8px',
                }}
                onClick={this.AddClassBtn.bind(this)}
              >
                管理(新建)班级
              </div>
            </div>
            <div className={'GrGameHeaderBox'} style={{ width: 1000 }}>
              <div className={'GrGameHeaderInfo'} style={{ width: 60 }}>
                <div
                  className={'CheckboxIco'}
                  style={{
                    backgroundImage:
                      this.state.CheckboxToggleOn === 'true'
                        ? 'url(' + CheckboxIcoHave + ')'
                        : 'url(' + CheckboxIcoNot + ')',
                  }}
                  onClick={this.CheckboxIcoAll.bind(this)}
                ></div>
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 60 }}>
                序号
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 160 }}>
                姓名
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 40 }}>
                性别
              </div>
              <div
                className={'GrGameHeaderInfo'}
                style={{ width: 260, position: 'relative' }}
              >
                <div
                  style={{
                    display: 'table',
                    margin: '0 auto',
                    position: 'relative',
                  }}
                  onClick={this.StuOfSchoolDown.bind(this)}
                >
                  <span
                    style={{
                      display: 'block',
                      float: 'left',
                      margin: '0 2px',
                      cursor: 'pointer',
                    }}
                  >
                    学校
                  </span>
                  <span
                    style={{
                      display: 'block',
                      float: 'left',
                      margin: '0 2px',
                      cursor: 'pointer',
                    }}
                  >
                    <Triangle
                      Direction={'down'}
                      Color={'#868686'}
                      Width={'8px'}
                      Height={'8px'}
                      Top={'6px'}
                      Right={'-12px'}
                    />
                  </span>
                </div>
                <div
                  className={'StuOfSchoolListBox scrollBar'}
                  style={{ display: this.state.StuOfSchoolDisplay }}
                >
                  {this.state.StuOfSchoolArr.map((item, index) => (
                    <div
                      className={'StuOfSchoolList'}
                      key={index}
                      onClick={() => this.StuOfSchoolList(item, index)}
                      style={
                        this.state.StuOfSchoolId === item.user_id
                          ? { backgroundColor: '#8DCEFD' }
                          : {}
                      }
                    >
                      {item.org_name}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={'GrGameHeaderInfo'}
                style={{ width: 260, position: 'relative' }}
              >
                <div
                  style={{
                    display: 'table',
                    margin: '0 auto',
                    position: 'relative',
                  }}
                  onClick={this.StuOfClassDown.bind(this)}
                >
                  <span
                    style={{
                      display: 'block',
                      float: 'left',
                      margin: '0 2px',
                      cursor: 'pointer',
                    }}
                  >
                    班级
                  </span>
                  <span
                    style={{
                      display: 'block',
                      float: 'left',
                      margin: '0 2px',
                      cursor: 'pointer',
                    }}
                  >
                    <Triangle
                      Direction={'down'}
                      Color={'#868686'}
                      Width={'8px'}
                      Height={'8px'}
                      Top={'6px'}
                      Right={'-12px'}
                    />
                  </span>
                </div>
                <div
                  className={'StuOfSchoolListBox scrollBar'}
                  style={{ display: this.state.StuOfClassDisplay }}
                >
                  {this.state.AddClassArr.map((item, index) => (
                    <div
                      className={'StuOfSchoolList'}
                      key={index}
                      onClick={() => this.StuOfClassList(item, index)}
                      style={
                        this.state.StuOfClassId === item.id
                          ? { backgroundColor: '#8DCEFD' }
                          : {}
                      }
                    >
                      {item.class_name}
                    </div>
                  ))}
                </div>
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 160 }}>
                操作
              </div>
            </div>
            <div
              className={'GrGameContentBox scrollBar'}
              style={{
                height: window.innerHeight - 320,
                width: 1000,
                margin: '0 auto',
              }}
            >
              {this.state.YCStuShowArr.map((item, index) => (
                <div
                  className={'GrGameContentList'}
                  key={index}
                  style={{ width: 1000 }}
                >
                  <div className={'GrGameContentInfo'} style={{ width: 60 }}>
                    <div
                      className={'CheckboxIco'}
                      style={{
                        backgroundImage:
                          item.Checkbox === 'true'
                            ? 'url(' + CheckboxIcoHave + ')'
                            : 'url(' + CheckboxIcoNot + ')',
                      }}
                      onClick={() => this.ClickOnCheckBoxIco(item, index)}
                    ></div>
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 60 }}>
                    {index + 1}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 160 }}>
                    {item.real_name}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 40 }}>
                    {item.gender}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 260 }}>
                    {item.school === ''
                      ? '无'
                      : item.school === null
                      ? '无'
                      : item.school}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 260 }}>
                    <div style={{ display: 'table', margin: '0 auto' }}>
                      <span
                        style={{
                          display: 'block',
                          float: 'left',
                          margin: '0 10px',
                          color: '#FDAD4D',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.YCStuClassClick(item, index)}
                      >
                        {item.class_name === ''
                          ? '无班级'
                          : item.class_name === null
                          ? '无班级'
                          : item.class_name}
                      </span>
                    </div>
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 160 }}>
                    <div style={{ display: 'table', margin: '0 auto' }}>
                      <span
                        style={{
                          display: 'block',
                          float: 'left',
                          margin: '0 10px',
                          color: '#6DC1FF',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.YCStuShowClick(item, index)}
                      >
                        数据分析
                      </span>
                      <span
                        style={{
                          display: 'block',
                          float: 'left',
                          margin: '0 10px',
                          color: '#ff0000',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.YCStuRemoveClick(item, index)}
                      >
                        删除
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={'YCAddStuInfoList clearfix'} style={{ marker: 10 }}>
              <div
                className={'YCOperatingBtn'}
                onClick={this.RankingBtn.bind(this)}
                style={{ margin: '0 20px' }}
              >
                成绩排行({this.state.AlreadyNum})
              </div>
            </div>
          </div>
        )}
        {this.state.YunChengState === 'Ranking' && (
          <Ranking
            RankingBackChild={this.RankingBackChild.bind(this)}
            SelectInfo={this.state.AlreadyNumArr}
            YCSchoolId={this.state.YCSchoolId}
            YCSchoolType={this.state.YCSchoolType}
          />
        )}
        {this.state.YunChengState === 'ECharts' && (
          <div>
            <div
              className={'DetailsPackageTop'}
              style={{ borderBottom: '2px solid #DDDDDD', padding: '6px 0' }}
            >
              <div
                className={'DetailsBackBox clearfix'}
                style={{ height: 20, marginTop: 4 }}
                onClick={this.EChartsBack.bind(this)}
              >
                <div
                  className={'DetailsBackIco'}
                  style={{ width: 20, height: 20 }}
                >
                  {' '}
                </div>
                <div
                  className={'DetailsBackWord'}
                  style={{
                    height: 20,
                    lineHeight: '20px',
                    fontSize: 16,
                    marginLeft: 6,
                  }}
                >
                  返回
                </div>
              </div>
            </div>
            <div className={'YunChengSelectBox'}>
              <div className={'YunChengTimeBox'}>
                <DatePicker
                  Type={'开始'}
                  BackTimeClick={this.StartTime.bind(this)}
                />
              </div>
              <div className={'YunChengTimeBox'}>
                <DatePicker
                  Type={'结束'}
                  BackTimeClick={this.EndTime.bind(this)}
                />
              </div>
              <div className={'YunChengItemBox clearfix'}>
                <div
                  className={'YCItemName'}
                  onClick={this.YCItemDown.bind(this)}
                >
                  {this.state.YCItemName === ''
                    ? '请选择项目'
                    : this.state.YCItemName}
                </div>
                <div
                  className={'YCItemIco'}
                  onClick={this.YCItemDown.bind(this)}
                >
                  <div className={'icon-arrow-down'}> </div>
                </div>
                <div
                  className={'YCItemNavBox scrollBar'}
                  style={{ display: this.state.YCItemDisplay }}
                >
                  {this.state.YCItemArr.map((item, index) => (
                    <div
                      className={'YCItemNav'}
                      key={index}
                      onClick={() => this.YCItemClick(item, index)}
                      style={
                        this.state.YCItemName === item.name
                          ? { backgroundColor: '#6DC1FF' }
                          : {}
                      }
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className={'YCButton'} onClick={this.YCButton.bind(this)}>
                确认
              </div>
            </div>
            {this.state.YCEChartsState === 'Logo' && (
              <div style={{ padding: '140px 0' }}>
                <div className={'TrainDataMain'}>
                  <div className={'keyNumLogo'}> </div>
                  <div className={'keyNumTitle'}>
                    请将开始时间、结束时间、项目选择完整进行数据分析！
                  </div>
                </div>
              </div>
            )}
            {this.state.YCEChartsState === 'LogoErr' && (
              <div style={{ padding: '140px 0' }}>
                <div className={'TrainDataMain'}>
                  <div className={'keyNumLogo'}> </div>
                  <div className={'keyNumTitle'}>{this.state.AnAlyErrMsg}</div>
                </div>
              </div>
            )}
            {this.state.YCEChartsState === 'LogoErr' &&
              this.state.YCScoreType === 'Err' && (
                <div style={{ padding: '140px 0' }}>
                  <div className={'TrainDataMain'}>
                    <div className={'keyNumLogo'}> </div>
                    <div className={'keyNumTitle'}>暂无分析数据！</div>
                  </div>
                </div>
              )}
            {this.state.YCEChartsState === 'Analysis' &&
              this.state.YCScoreType === 'OK' && (
                <div className={'EChartsBox'}>
                  <div
                    style={{
                      width: 800,
                      height: 396,
                      margin: '20px auto',
                      border: '1px solid #037cd6',
                    }}
                  >
                    <div
                      className={'MainListBox clearfix'}
                      style={{
                        backgroundColor: '#F1F4FA',
                        position: 'relative',
                      }}
                    >
                      <div className={'MainList'} style={{ width: 60 }}>
                        序号
                      </div>
                      <div className={'MainList'} style={{ width: 330 }}>
                        日期
                      </div>
                      <div className={'MainList'} style={{ width: 400 }}>
                        成绩/时间
                      </div>
                      <div
                        className={'ZXXIco DownloadIco'}
                        title={'数据导出'}
                        onClick={this.DownData.bind(this)}
                      >
                        {' '}
                      </div>
                    </div>
                    <div
                      className={'MainContentBox scrollBar'}
                      style={{ height: 368 }}
                    >
                      {this.state.YCScoreArr.map((item, index) => (
                        <div className={'MainListBox clearfix'} key={index}>
                          <div className={'MainList'} style={{ width: 60 }}>
                            {index + 1}
                          </div>
                          <div className={'MainList'} style={{ width: 330 }}>
                            {item.ctime}
                          </div>
                          {item.time_score === 0 && (
                            <div
                              className={'MainList'}
                              style={{ width: 400, fontSize: 13 }}
                            >
                              {item.issubmit === 0 && <span>未提交</span>}
                              {item.issubmit === 1 && (
                                <div>
                                  {item.iscrash === 0 && (
                                    <span>{item.total_time + '秒'}</span>
                                  )}
                                  {item.iscrash === 1 && <span>坠机</span>}
                                  {item.iscrash === 2 && (
                                    <span>违规：{item.remark}</span>
                                  )}
                                  {item.iscrash === 3 && <span>弃权</span>}
                                  {item.iscrash === 4 && <span>超时</span>}
                                </div>
                              )}
                            </div>
                          )}
                          {item.time_score === 1 && (
                            <div
                              className={'MainList'}
                              style={{ width: 400, fontSize: 13 }}
                            >
                              {item.issubmit === 0 && <span>未提交</span>}
                              {item.issubmit === 1 && (
                                <div>
                                  {item.iscrash === 0 && (
                                    <span>{item.total_score + '分'}</span>
                                  )}
                                  {item.iscrash === 1 && <span>坠机</span>}
                                  {item.iscrash === 2 && (
                                    <span>违规：{item.remark}</span>
                                  )}
                                  {item.iscrash === 3 && <span>弃权</span>}
                                  {item.iscrash === 4 && <span>超时</span>}
                                </div>
                              )}
                            </div>
                          )}
                          {item.time_score === 2 && (
                            <div
                              className={'MainList'}
                              style={{ width: 400, fontSize: 13 }}
                            >
                              {item.issubmit === 0 && <span>未提交</span>}
                              {item.issubmit === 1 && (
                                <div>
                                  {item.iscrash === 0 && (
                                    <span>
                                      {item.total_time + '秒'}/
                                      {item.total_score + '分'}
                                    </span>
                                  )}
                                  {item.iscrash === 1 && <span>坠机</span>}
                                  {item.iscrash === 2 && (
                                    <span>违规：{item.remark}</span>
                                  )}
                                  {item.iscrash === 3 && <span>弃权</span>}
                                  {item.iscrash === 4 && <span>超时</span>}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            {this.state.YCEChartsState === 'Analysis' &&
              this.state.YCScoreType === 'OK' && (
                <ECharts
                  EChartsBackChild={this.EChartsBackChild.bind(this)}
                  SelectInfo={this.state.SelectInfo}
                />
              )}
            {this.state.YCEChartsState === 'Analysis' &&
              this.state.IsAnAly === true &&
              this.state.AnAlyState === 'no' && (
                <div style={{ width: '100%', margin: '20px 0', height: 36 }}>
                  <div
                    className={'OtherEChartsBtn'}
                    onClick={this.OtherEChartsBtn.bind(this)}
                  >
                    >>>>>>
                    <span style={{ textDecoration: 'underline' }}>
                      竞技类项目点击查看其他具体分析
                    </span>
                  </div>
                </div>
              )}
            {this.state.YCEChartsState === 'Analysis' &&
              this.state.IsAnAly === true &&
              this.state.AnAlyState === 'yes' && (
                <div>
                  <div style={{ width: '100%', margin: '20px 0', height: 36 }}>
                    <div
                      className={'OtherEChartsBtn'}
                      onClick={this.OutOtherEChartsBtn.bind(this)}
                    >
                      >>>>>>
                      <span style={{ textDecoration: 'underline' }}>收起</span>
                    </div>
                  </div>
                  <AnAlyECharts
                    IsAnAlyArr={this.state.IsAnAlyArr}
                    SelectInfo={this.state.SelectInfo}
                  />
                </div>
              )}
          </div>
        )}
        {this.state.YunChengState === 'Add' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{
                borderBottom: '2px solid #DDDDDD',
                padding: '6px 0',
                height: 36,
              }}
            >
              <div className={'AppSearchBox clearfix'}>
                <div className={'AppSearchInputBox'}>
                  <input
                    className={'SearchInput'}
                    type={'text'}
                    name={'SearchInput'}
                    value={this.state.AddStuNumIdCard}
                    onChange={this.AddStuNumIdCard.bind(this)}
                    placeholder={'请输入学员ID或身份证证号'}
                    autoComplete={'off'}
                  />
                </div>
                <div
                  className={'AppSearchBtn'}
                  onClick={this.AddStuNumIdCardSearchBtn.bind(this)}
                >
                  确认
                </div>
              </div>
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.AddBack.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
              <div className={'AddStuBtn'} onClick={this.AddListBtn.bind(this)}>
                添加纪录
              </div>
            </div>
            {this.state.AddStuNumIdCardInfo === 'yes' && (
              <div>
                <div
                  className={'YCAddStuInfoList'}
                  style={{ height: 200, marginBottom: 10 }}
                >
                  <img src={this.state.AddPeopleInfo.image} alt={''} />
                </div>
                <div className={'YCAddStuInfoList clearfix'}>
                  <span
                    style={{ width: 160, textAlign: 'right', color: '#888888' }}
                  >
                    姓名：
                  </span>
                  <span
                    style={{
                      width: 'auto',
                      textAlign: 'left',
                      textIndent: '0.2em',
                    }}
                  >
                    {this.state.AddPeopleInfo.real_name}
                  </span>
                </div>
                <div className={'YCAddStuInfoList clearfix'}>
                  <span
                    style={{ width: 160, textAlign: 'right', color: '#888888' }}
                  >
                    ID：
                  </span>
                  <span
                    style={{
                      width: 'auto',
                      textAlign: 'left',
                      textIndent: '0.2em',
                    }}
                  >
                    {this.state.AddPeopleInfo.yy_num}
                  </span>
                </div>
                <div className={'YCAddStuInfoList clearfix'}>
                  <span
                    style={{ width: 160, textAlign: 'right', color: '#888888' }}
                  >
                    联系方式：
                  </span>
                  <span
                    style={{
                      width: 'auto',
                      textAlign: 'left',
                      textIndent: '0.2em',
                    }}
                  >
                    {this.state.AddPeopleInfo.mobile}
                  </span>
                </div>
                {/*<div className={'YCAddStuInfoList clearfix'}>*/}
                {/*    <span style={{width:160,textAlign:'right',color:'#888888'}}>教练员：</span>*/}
                {/*    <span style={{width:'auto',textAlign:'left',textIndent:'0.2em',}}>{this.state.AddPeopleInfo.iscoach === 1 ? '是' : '否'}</span>*/}
                {/*</div>*/}
                <div className={'YCAddStuInfoList clearfix'}>
                  <div
                    className={'AddStuBtn'}
                    onClick={this.AddStuBtnOk.bind(this)}
                    style={{
                      float: 'left',
                      textIndent: '0',
                      margin: '40px 0 0 100px',
                    }}
                  >
                    添加
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.YunChengState === 'AddClassBtn' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{
                borderBottom: '2px solid #DDDDDD',
                padding: '6px 0',
                height: 36,
              }}
            >
              <div className={'AppSearchBox clearfix'}>
                <div className={'AppSearchInputBox'}>
                  <input
                    className={'SearchInput'}
                    type={'text'}
                    name={'SearchInput'}
                    value={this.state.AddClassName}
                    onChange={this.AddClassName.bind(this)}
                    placeholder={'请输入新建的班级名称'}
                    autoComplete={'off'}
                  />
                </div>
                <div
                  className={'AppSearchBtn'}
                  style={{ backgroundColor: '#FDAD4D' }}
                  onClick={this.AddClassOkBtn.bind(this)}
                >
                  确认新建
                </div>
              </div>
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.AddBack.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
            </div>
            <div
              className={'YcCodeShowList'}
              style={{ color: '#030303', width: '100%', fontSize: 16 }}
            >
              已有班级：
            </div>
            {this.state.AddClassArrTip === 'yes' && (
              <div
                className={'YcCodeShowBox scrollBar clearfix'}
                style={{ height: window.innerHeight - 290, marginBottom: 0 }}
              >
                {this.state.AddClassArr.map((item, index) => (
                  <div
                    className={'YcCodeShowList clearfix'}
                    key={index}
                    style={{
                      color: '#009944',
                      width: 465,
                      border: '1px solid #FDAD4D',
                      margin: 10,
                    }}
                  >
                    <span
                      className={'SLH'}
                      style={{ float: 'left', width: 430 }}
                    >
                      {item.class_name}
                    </span>
                    <span
                      className={'SLHBackIco'}
                      style={{ float: 'right', width: 24, color: '#ff0000' }}
                      onClick={() => this.ClassRemove(item, index)}
                    >
                      {' '}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {this.state.AddClassArrTip === 'no' && (
              <div
                className={'YcCodeShowBox scrollBar clearfix'}
                style={{ height: window.innerHeight - 290, marginBottom: 0 }}
              >
                <div className={'YcCodeShowList'}>暂无班级！</div>
              </div>
            )}
          </div>
        )}
        {this.state.YunChengState === 'AddList' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{
                borderBottom: '2px solid #DDDDDD',
                padding: '6px 0',
                height: 36,
              }}
            >
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.AddListBack.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
            </div>
            <div className={'GrGameHeaderBox'}>
              <div className={'GrGameHeaderInfo'} style={{ width: 50 }}>
                序号
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 200 }}>
                姓名
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 90 }}>
                性别
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 400 }}>
                学校
              </div>
              <div className={'GrGameHeaderInfo'} style={{ width: 160 }}>
                状态
              </div>
            </div>
            <div
              className={'GrGameContentBox scrollBar'}
              style={{ height: 660 }}
            >
              {this.state.AddListArr.map((item, index) => (
                <div className={'GrGameContentList'} key={index}>
                  <div className={'GrGameContentInfo'} style={{ width: 50 }}>
                    {index + 1}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 200 }}>
                    {item.real_name}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 90 }}>
                    {item.gender}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 400 }}>
                    {item.school === ''
                      ? '无'
                      : item.school === null
                      ? '无'
                      : item.school}
                  </div>
                  <div className={'GrGameContentInfo'} style={{ width: 160 }}>
                    {item.status === 0 && (
                      <span style={{ color: '#6DC1FF' }}>等待回复</span>
                    )}
                    {item.status === 1 && (
                      <span style={{ color: '#009944' }}>已同意</span>
                    )}
                    {item.status === 2 && (
                      <span style={{ color: '#ff0000' }}>已拒绝</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {this.state.YunChengState === 'YcCode' && (
          <div>
            <div
              className={'DetailsPackageTop clearfix'}
              style={{
                borderBottom: '2px solid #DDDDDD',
                padding: '6px 0',
                height: 36,
              }}
            >
              <div
                className={'DetailsBackBox clearfix'}
                style={{ marginTop: 6 }}
                onClick={this.CodeBack.bind(this)}
              >
                <div className={'DetailsBackIco'}> </div>
                <div className={'DetailsBackWord'}>返回</div>
              </div>
            </div>
            <div className={'AppSearchBox clearfix'} style={{ float: 'unset' }}>
              <div className={'AppSearchInputBox'}>
                <input
                  className={'SearchInput'}
                  type={'text'}
                  name={'SearchInput'}
                  value={this.state.YcCodeInput}
                  onChange={this.YcCodeInput.bind(this)}
                  placeholder={'请输入授权码！'}
                  autoComplete={'off'}
                />
              </div>
              <div
                className={'AppSearchBtn'}
                onClick={this.YcCodeInputBtn.bind(this)}
              >
                确认
              </div>
            </div>
            <div
              className={'YcCodeShowBox scrollBar clearfix'}
              style={{ height: window.innerHeight - 290, marginBottom: 0 }}
            >
              {this.state.YcCodeArr.map((item, index) => (
                <div
                  className={'YcCodeShowList'}
                  key={index}
                  style={{
                    color:
                      item.isvalid === 1
                        ? '#009944'
                        : item.mac_isdel
                        ? ''
                        : item.is_expire === 1
                        ? '#FF626C'
                        : '#037cd6',
                    border: '1px solid #000',
                    margin: '4px 2px',
                  }}
                >
                  激活码：
                  {item.verificat_code}(
                  {item.isvalid === 1
                    ? '未绑定'
                    : item.mac_isdel
                    ? '已废弃'
                    : item.is_expire === 1
                    ? '已到期'
                    : '已绑定'}
                  )
                  {item.mac && (
                    <div style={{ fontSize: 13, textIndent: '1em' }}>
                      MAC地址：{item.mac}
                    </div>
                  )}
                  {item.expire_time && (
                    <div style={{ fontSize: 13, textIndent: '1em' }}>
                      MAC到期时间：{item.expire_time}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {this.state.YunChengPopUp === 'YCMoveClass' && (
          <div className={'PopUpBoxBg'} style={{ minHeight: 600 }}>
            <div className={'AddItemBox'} style={{ width: 400 }}>
              <div
                className={'AddItemBigTitle'}
                style={{ textIndent: 0, textAlign: 'center' }}
              >
                学员班级添加转移
                <div
                  className={'AddItemBackIco'}
                  onClick={this.ClassCrossOut.bind(this)}
                >
                  {' '}
                </div>
              </div>
              <div
                className={'MainListBox clearfix'}
                style={{ backgroundColor: '#F1F4FA', width: 400 }}
              >
                <div
                  className={'MainList'}
                  style={{ width: '75%', minWidth: '300px' }}
                >
                  班级
                </div>
                <div
                  className={'MainList'}
                  style={{ width: '25%', minWidth: '100px' }}
                >
                  操作
                </div>
              </div>
              <div
                className={'AddItemMainBox scrollBar'}
                style={{ height: 530 }}
              >
                {this.state.AddClassArr.map((item, index) => (
                  <div
                    className={'MainListBox clearfix'}
                    key={index}
                    style={{ width: '400px' }}
                  >
                    <div
                      className={'MainList'}
                      style={{ width: '73%', minWidth: '292' }}
                    >
                      {item.class_name}
                    </div>
                    <div
                      className={'MainList'}
                      style={{ width: '25%', minWidth: '100px' }}
                    >
                      <div style={{ display: 'table', margin: '0 auto' }}>
                        <span
                          style={{
                            display: 'block',
                            float: 'left',
                            margin: '0 10px',
                            color: '#009944',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => this.ClassConfirm(item, index)}
                        >
                          选择进班
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.PopUp === '提示弹窗' && (
          <PopUp
            ChildBackClick={this.ChildBackTip.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
      </div>
    )
  }
}

export default GrYunCheng
