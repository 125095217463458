import React, { Component } from 'react'
import './inquire.css'
import { get } from '../../ajax/tools'

class EquipmentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      EquipmentId: '',
      EquipmentArr: {},
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const history = nextProps
    this.setState({
      EquipmentId: history.EquipmentId,
    })
  }

  componentDidMount = function () {
    const history = this.props
    get({ url: 'query/device_query_detail/' + history.EquipmentId }).then(
      (res) => {
        if (res.errno === '200') {
          this.setState({
            EquipmentArr: res.data[0],
          })
        }
      }
    )
    this.setState({
      EquipmentId: history.EquipmentId,
    })
  }

  BackButton = () => {
    this.props.BackButton('BackBtn')
  }

  EquipmentWebLink() {
    window.open(this.state.EquipmentArr.website, '_blank')
  }

  render() {
    return (
      <div
        className={'DetailsPackageBox'}
        style={{
          minHeight:
            window.innerHeight - 194 > 500 ? window.innerHeight - 194 : 500,
        }}
      >
        <div className={'DetailsPackageTop'}>
          <div
            className={'DetailsBackBox clearfix'}
            onClick={this.BackButton.bind(this)}
          >
            <div className={'DetailsBackIco'}> </div>
            <div className={'DetailsBackWord'}>返回</div>
          </div>
        </div>
        <div className={'DetailsMainBox clearfix'}>
          <div className={'EquipmentDetailsLeft'}>
            <div className={'EquipmentPhotoBox'}>
              <img alt={''} src={this.state.EquipmentArr.image} />
            </div>
            {/*<button className={'BuyEquipmentBtn'}>立即购买</button>*/}
          </div>
          <div className={'EquipmentDetailsRight'}>
            <div className={'EquipmentDetailsTopTit'}>
              <div className={'DetailsTopTitWord'}>设备详情</div>
              <div className={'DetailsTopTitBorder'}> </div>
            </div>
            <div className={'EquipmentInfo clearfix'}>
              <div className={'EquipmentInfoLeftBox'}>
                <div className={'EquipmentInfoListBox clearfix'}>
                  <div className={'EquipmentInfoTit'}>设备名称</div>
                  <div className={'EquipmentInfoCon EquipmentInfoConLeft'}>
                    {this.state.EquipmentArr.name}
                  </div>
                </div>
                <div className={'EquipmentInfoListBox clearfix'}>
                  <div className={'EquipmentInfoTit'}>设备型号</div>
                  <div className={'EquipmentInfoCon EquipmentInfoConLeft'}>
                    {this.state.EquipmentArr.model}
                  </div>
                </div>
                <div className={'EquipmentInfoListBox clearfix'}>
                  <div className={'EquipmentInfoTit'}>品牌名称</div>
                  <div className={'EquipmentInfoCon EquipmentInfoConLeft'}>
                    {this.state.EquipmentArr.brand}&nbsp;&nbsp;
                    <div
                      style={{
                        fontSize: 12,
                        letterSpacing: '0.06em',
                        color: '#00b9f0',
                        textDecoration: 'underline',
                        display: 'inline-block',
                      }}
                      onClick={this.EquipmentWebLink.bind(this)}
                      className={'EquipmentWebLink'}
                    >
                      {this.state.EquipmentArr.website}
                    </div>
                  </div>
                </div>
                {this.state.EquipmentArr.ispay === 1 && (
                  <div className={'EquipmentInfoListBox clearfix'}>
                    <div className={'EquipmentInfoTit'}>联系人</div>
                    <div className={'EquipmentInfoCon EquipmentInfoConLeft'}>
                      {this.state.EquipmentArr.contact}（
                      {this.state.EquipmentArr.mobile}）
                    </div>
                  </div>
                )}
              </div>
              <div className={'EquipmentInfoRightBox'}>
                <div className={'EquipmentInfoListBox clearfix'}>
                  <div className={'EquipmentInfoTit'}>适用等级</div>
                  <div className={'EquipmentInfoCon EquipmentInfoConRight'}>
                    {this.state.EquipmentArr.level}
                  </div>
                </div>
                <div className={'EquipmentInfoListBox clearfix'}>
                  <div className={'EquipmentInfoTit'}>证书编号</div>
                  <div className={'EquipmentInfoCon EquipmentInfoConRight'}>
                    {this.state.EquipmentArr.code}
                  </div>
                </div>
                <div className={'EquipmentInfoListBox clearfix'}>
                  <div className={'EquipmentInfoTit'}>证书有效期</div>
                  <div className={'EquipmentInfoCon EquipmentInfoConRight'}>
                    {this.state.EquipmentArr.expire_time}
                  </div>
                </div>
              </div>
            </div>
            <div className={'EquipmentDetailsTopTit'}>
              <div className={'DetailsTopTitWord'}>设备基本功能简介</div>
              <div className={'DetailsTopTitBorder'}> </div>
            </div>
            <div className={'EquipmentInfo EquipmentIntroduction clearfix'}>
              {this.state.EquipmentArr.remark}
            </div>
            {/*
                                this.state.EquipmentArr.image !== ''&&
                                <div className={'EquipmentInfo'}>
                                    <img alt={''}
                                         src={linshifle}
                                         style={{width:'100%',height:'auto',display:'block',border:'1px solid #030303',}}
                                    />
                                </div>
                            */}
            {/*
                                <div className={'EquipmentDetailsTopTit'}>
                                    <div className={'DetailsTopTitWord'}>产品参数</div>
                                    <div className={'DetailsTopTitBorder'}> </div>
                                </div>
                                <div className={'EquipmentInfo clearfix'}>
                                    <div className={'EquipmentInfoCenterBox'}>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>包装尺寸</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>连接方式</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>使用对象</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                    </div>
                                    <div className={'EquipmentInfoCenterBox'}>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>产品毛重</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>产品类型</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                    </div>
                                    <div className={'EquipmentInfoCenterBox'}>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>产品特点</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                        <div className={'EquipmentInfoListBox clearfix'}>
                                            <div className={'EquipmentInfoTit'}>产品产地</div>
                                            <div className={'EquipmentInfoCon'}> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'EquipmentDetailsTopTit'}>
                                    <div className={'DetailsTopTitWord'}>其它购买渠道</div>
                                    <div className={'DetailsTopTitBorder'}> </div>
                                </div>
                                <div className={'OtherBuyBtnBox'}>
                                    <button className={'OtherBuyEquipmentBtn'}>其它购买</button>
                                    <button className={'OtherBuyEquipmentBtn'}>其它购买</button>
                                </div>
                            */}
          </div>
        </div>
      </div>
    )
  }
}

export default EquipmentDetails
